.container {
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: inherit;
  background-position: center;
  div {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    div {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      // flex: '0 0 40%',
      width: '100%';
    }
    div:nth-child(2) {
      height: 250px;
    }
  }
}
