.nftImage {
  width: 25%;
  max-width: 235px;
}
.samgorLogo{
  height: 60px;
}
.title2{
  color: #ffffff;
  font-weight: 700;
  font-size: 80px;
  font-style: italic;
  line-height: 80px;
  padding-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .title2{
    font-size: 45px;
    line-height: 45px;
  }
  .logoImages{
    height: 45px;
  }
}
