.timelineContainer{ 
    position: relative;
    width: 70%;
    overflow: hidden;
}

@media screen and (max-width: 768px) {
    .timelineContainer{ 
        width: 100%;
    }
}    