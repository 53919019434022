.mintingArrangementContainer{
    display: flex;
    margin: 0px 20% 40px;
}

.mintingArrangementDate{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 50px;
    border-radius: 20px;
    background: linear-gradient(to right, rgba(54, 19, 63, 1), rgba(67, 69, 91, 0.26));
    margin-bottom: 50px;
    .mintingArrangementDate__item{
        display: flex;
        flex-direction: column;
        align-content: center;
        text-align: center;
        margin-right: 40px;
        @media screen and (max-width: 768px) {
            margin-right: 10px;
        }
        &:last-child{
            margin-right: 0;
        }
        span.text{
            color: rgb(255, 255, 255);
            font-weight: 400;
            font-size: 18px;
            line-height: 27px;
        }
    }
    .mintingArrangementDate__datetime{
        display: flex;
        align-items: baseline;
        padding-bottom: 20px;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: center;
        }
        span{
            color: rgb(255, 255, 255);
            font-weight: 500;
            &:first-child{
                font-size: 36px;
                line-height: 54px;
                @media screen and (max-width: 768px) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
            &:last-child{
                line-height: 30px;
                @media screen and (max-width: 768px) {
                    line-height: 24px;
                }
            }
        }    
    }
}

.sampleNFTImage{
    width: 200px;
    margin-bottom: 30px;
}

@media screen and (max-width: 600px) {
    .mintingArrangementContainer{
        margin: 0px 0% 40px;
    }
    .mintingArrangementDate{
        padding: 30px 10px;
        margin: 0px 10px 20px 10px;
    }
    .sampleNFTImage{
        width: 160px;
    }
}