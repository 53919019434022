.mintSoonBtn{
  @media screen and (max-width: 400px) {
    display: none!important;
  }
}

.tamjaiInternationalLogo{
  width: 141px;
}

.menuList {
  display: flex;
  align-items: center;
  @media screen and (max-width: 1095px) {
    display: none;
  }
}

@media screen and (min-width: 1095px) {
  .menuIcon{
    display: none!important;
  }
}

@media screen and (max-width: 768px) {
  .langENBtn, .langZHBtn{
    min-width: initial!important;
  }
}