.igImage{
    margin-bottom: 50px;
    @media screen and (max-width: 768px) {
        width: 80%;
        margin: auto;
        margin-bottom: 50px;
    }
}
.igContent{
    color: #ffffff;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    margin: 0px 20% 80px;
}
.whatsMore{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 10%;

    .whatsMoreContent{ 
        width: 50%;
        display: flex;
        flex-direction: column;
        padding-right: 50px;
    }

    .jehJeh{
        width: 320px;
        height: 200px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        .whatsMoreContent{
            width: 100%;
            padding-right: 0px;
            margin: auto;
        }
    }
}
