.auctionImg {
  width: 200px;
  margin-bottom: 30px;
  border-radius: 20px;
  @media screen and (max-width: 768px) {
    width: 150px;
  }
}

.avatarTitle {
  color: #ffffff;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  // height: 56px;
  // @media screen and (max-width: 1000px) {
  //   height: 92px;
  // }
  // @media screen and (max-width: 700px) {
  //   height: 120px;
  // }
}
