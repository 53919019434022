a {
    text-decoration-color: white!important;
}
.registrationSuccessContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    margin: 0px 20%;
    padding: 50px;
    color: #ffffff;
    min-width: 300px;
    max-width: 500px;
    @media screen and (max-width: 768px) {
        padding: 25px;
    }
}

.registrationForm{
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    margin: 0px 20%;
    padding: 50px;
    color: #ffffff;
    min-width: 250px;
    max-width: 500px;

    .samgorLogo{
        height: 60px;
    }

    @media screen and (max-width: 768px) {
        margin: 0px;
        padding: 30px;
    }
}

@media screen and (max-width: 768px) {
    .logoImages{
      height: 45px;
    }
  }
  