.footer{
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    box-sizing: border-box;
    padding: 30px 33px;
    background: linear-gradient(94.74deg, rgb(54, 19, 63) 16.92%, rgba(67, 69, 91, 0.42) 103.19%, rgb(40, 43, 68) 103.19%);
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    .footer__copyright{
        margin-right: 65px;
        color: rgb(255, 255, 255);
    }
    .footer__url{
        margin-right: 30px;
        color: rgb(255, 255, 255);
    }
}

@media screen and (max-width: 890px) {
    .footer{
        display: block;
        padding: 20px 10%;
        .footer__copyright{
            margin-bottom: 30px;
        }
        .footer__url{
            display: block;
            margin-bottom: 20px;
        }
    }
}