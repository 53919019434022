.mountain{
    width: 100%;
}

.questionList{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 10%;
    .questionList__item{
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .MuiAccordionSummary-content span{
        font-size: 18px;
    }   
    .MuiAccordionSummary-root{
        line-height: 18px!important;
    }
    .questionList{
        margin: 0px 20px;    
    }
}    

@media screen and (max-width: 550px) {
    .mountain{
        margin-top: 100px
    }
}