.biggerTransparentContainer {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 30px;
  width: 250px;
  margin: 0px 20px 20px;
  background: linear-gradient(to right, rgba(54, 19, 63, 1), rgba(67, 69, 91, 0.26));
  @media screen and (max-width: 500px) {
    margin: 0px 0px 20px;
  }
}
.smallerTransparentContainer {
  display: flex;
  border-radius: 20px;
  padding: 20px 30px;
  width: 150px;
  background: linear-gradient(to right, rgba(54, 19, 63, 1), rgba(67, 69, 91, 0.26));
  margin-right: 24px;
  margin-bottom: 24px;
}

ul {
  list-style: none;
  padding: 0px;
  margin: 0;
}

ul li::before {
  content: '\2022';
  color: #ff8a00;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.quotaRelative {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media screen and (max-width: 890px) {
    margin-top: 100px;
  }
}
.quota {
  position: absolute;
  transform: translate(120%, -50%);
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: contain;
  width: 188px;
  height: 112px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 890px) {
    transform: translate(0%, -100%);
  }
}

.secondPartImgContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  img {
    width: 227px;
    height: 227px;
  }
  img:nth-child(2) {
    width: 273px;
    height: 273px;
  }

  @media screen and (max-width: 810px) {
    img {
      width: 140px;
      height: 140px;
    }
    img:nth-child(2) {
      width: 190px;
      height: 190px;
    }
  }

  @media screen and (max-width: 500px) {
    img {
      width: 80px;
      height: 80px;
    }
    img:nth-child(2) {
      width: 120px;
      height: 120px;
    }
  }
}

.celebrityImg {
  width: 200px;
  margin-bottom: 30px;
  border-radius: 20px;
  @media screen and (max-width: 500px) {
    width: 160px;
  }
}
